.main {
  & .section {
    max-width: 1280px;
    margin: 0 auto;

    & .cta {
      text-align: center;
      width: 100%;
    }
  }

  & > .section.multi-accordion ~ .section.multi-accordion .macro-multi-accordion.collapsed {
    margin-top: -41px;
  }
}

.section {
  & ol {
    margin: 0.7rem 0 1rem 0;
    list-style-type: none;
    counter-reset: ol-counter;

    & li {
      margin-left: -10px;

      &::before {
        content: "" counter(ol-counter) " ";
        counter-increment: ol-counter;
        margin: 2.5px 0 0 -25px;
        position: absolute;
        font-family: var(--handwritten-font-family);
        font-size: var(--font-size-m);
      }

      &:nth-of-type(9) ~ li::before {
        content: counter(ol-counter) " ";
      }
    }
  }

  & ul {
    margin: 0.7rem 0 1rem 0;
    list-style-type: none;

    & li {
      margin-left: -10px;

      &::before {
        content: "•";
        margin: 2.5px 0 0 -25px;
        position: absolute;
        font-family: var(--handwritten-font-family);
        font-size: var(--font-size-m);
      }
    }
  }
}

.trustpilot-reviews {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section.hero {
  & .hero-header {
    width: 100%;
    position: relative;

    & .background-image {
      position: relative;
      grid-column: 1 / 5;
      background-size: cover;
      background-repeat: no-repeat;
    }

    & .hero-text {
      position: relative;
      grid-column: 1 / 5;
      display: flex;
      flex-direction: column;
      padding: 0 12px;

      & h1 {
        font-family: var(--handwritten-font-family);
        margin-top: 0;
        margin-bottom: var(--spacing-s);
        color: var(--primary-blueberry);
      }

      & .title-underline {
        margin-bottom: var(--spacing-s);

        & img {
          width: 124px;
        }
      }

      & .montserrat {
        font-family: var(--default-font-family);
      }

      & .christmas-font {
        font-family: var(--christmas-font-family);
        font-size: 48px;
        line-height: 52px;
        color: var(--navy-00);
      }

      & .hero-text-extra {
        & p {
          margin: 0;
          font-size: var(--font-size-s);
          line-height: 32px;

          &:empty {
            display: none;
          }
        }

        & .tails-cta {
          margin-top: 20px;
          background-color: var(--primary-blueberry);
        }
      }

      & .trustpilot-reviews {
        margin-top: var(--spacing-s);

        & p {
          font-size: var(--font-size-body);
          margin-bottom: 4px;
          margin-top: 0;
          line-height: 24px;

          &.excellent {
            font-weight: bold;
          }

          &.excellent img {
            width: 85px;
            vertical-align: text-top;
            margin-left: var(--spacing-xxs);
          }

          & a:focus-visible {
            box-shadow: 0 0 10px 2px var(--secondary-03);
          }
        }

        & a img {
          height: 20px;
          vertical-align: top;
          margin-left: 4px;
        }
      }
    }

    @media (--desktop) {
      & .hero-text {
        padding: 0 30px 0 0;
      }
    }

    &.mobile-below-copy {
      grid-template-rows: repeat(2, auto);

      @media (max-width: 767px) {
        row-gap: 0;

        & .hero-sticker {
          bottom: 12px;
          left: 12px;
        }
      }

      & .background-image {
        grid-row: 2 / 3;
        height: 360px;
        background-position: center calc(150px - 45vw);

        &::after {
          content: '';
          height: 100%;
          width: 100%;
          position: absolute;
          background: var(--primary-white);
          bottom: 69%;
          left: 0;
          clip-path: inset(0 35% 0 0);
        }
      }

      & .hero-text {
        grid-row: 1 / 2;
        justify-content: flex-start;
        padding-top: var(--spacing-s);
      }
    }

    &.mobile-above-copy {
      grid-template-rows: repeat(2, auto);
      background: var(--primary-blueberry-light-30);

      & .background-image {
        grid-row: 1 / 2;
        background-position: center;
        width: 100%;
        height: 300px;
      }

      & .hero-text {
        grid-row: 2 / 3;
        justify-content: center;

        & h1 {
          margin-top: var(--spacing-s);
          margin-bottom: var(--spacing-xs);
        }
      }

      @media (max-width: 767px) {
        & .hero-sticker {
          top: 104px;
          right: 12px;
        }
      }
    }

    &[class*='desktop'] {
      background: var(--primary-blueberry-light-30);

      @media (--tablet) {
        grid-template-rows: repeat(10, 32px);

        & .background-image {
          grid-row: 1 / 11;
          grid-column: 1 / 6;
          background-position: center;
          height: auto;

          &::before {
            display: none;
          }

          &::after {
            display: none;
          }
        }

        & .hero-text {
          justify-content: center;
          grid-row: 2 / 10;
          grid-column: 4 / 7;
          background: transparent;
          clip-path: none;

          & h1 {
            margin-top: 0;
            margin-bottom: var(--spacing-xxs);
          }
        }
      }

      @media (--desktop) {
        & .background-image {
          grid-column: 1 / 7;
          background-position: left;
        }

        & .hero-text {
          grid-column: 7 / 13;

          &.trustpilot-reviews {
            margin-top: var(--spacing-m);

            & p a:focus-visible {
              box-shadow: 0 0 10px 2px var(--secondary-03);
            }
          }
        }
      }

      &.desktop-shape-bottom {
        @media (--tablet) {
          & .background-image {
            grid-column: 1 / 7;
            clip-path: circle(calc(250px + 20%) at calc(20px + 40%) -10%);

            @media (--desktop) {
              grid-column: 1 / 12;
              clip-path: circle(calc(360px + 15%) at calc(200px + 20%) -28%);
            }
          }
        }
      }
    }

    &.dark-blueberry {
      background-color: var(--primary-blueberry);

      & #hero-text {
        & h1 {
          color: var(--primary-blueberry-light);
        }

        & .hero-text-extra {
          & p {
            color: var(--primary-white);
          }

          & a.tails-cta {
            color: var(--primary-black);
            background-color: var(--primary-blueberry-light);
          }
        }
      }
    }
  }

  & .hero-sticker {
    height: 130px;
    width: 130px;
    box-sizing: border-box;
    padding: 8px 12px;
    border-radius: 50%;
    position: absolute;
    margin-top: var(--spacing-l);
    display: flex;
    align-items: center;
    bottom: 30px;
    left: 30px;

    & .hero-sticker-copy {
      max-width: 100%;
      max-height: 100%;
      text-align: center;
      color: var(--navy-00);
      overflow: hidden;
      -webkit-box-orient: vertical;
      display: block;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      font-size: 24px;
      line-height: 120%;
      font-family: var(--display-font-family);
      font-weight: var(--font-weight-bold);
      transform: rotate(-3deg);
      margin: 0;
    }

    &.hero-sticker-blueberry {
      background-color: var(--primary-blueberry);

      & .hero-sticker-copy {
        color: var(--primary-blueberry-light);
      }
    }

    &.hero-sticker-potato {
      background-color: var(--secondary-potato);

      & .hero-sticker-copy {
        color: var(--secondary-potato-light);
      }
    }

    &.hero-sticker-kibble {
      background-color: var(--secondary-kibble);

      & .hero-sticker-copy {
        color: var(--secondary-kibble-light);
      }
    }

    &.hero-sticker-pear {
      background-color: var(--secondary-pear);

      & .hero-sticker-copy {
        color: var(--secondary-pear-light);
      }
    }

    &.hero-sticker-christmas-red {
      background-color: var(--christmas-red);

      & .hero-sticker-copy {
        color: var(--primary-white);
      }
    }
  }
}

.section.feature-centered {
  & .macro-feature-centered {
    &.mobile-only {
      @media (--desktop) {
        display: none;
      }
    }

    &.desktop-only {
      display: none;

      @media (--desktop) {
        display: block;
      }
    }

    & .feature {
      & .tails-grid {
        padding: var(--spacing-xs) 12px;
        max-width: 1024px;
        margin: 0 auto;

        @media (--phone) {
          padding: var(--spacing-xs) var(--spacing-m);
        }

        @media (--tablet) {
          padding: var(--spacing-xs) var(--spacing-l);
        }

        @media (--desktop) {
          padding: var(--spacing-xs) var(--spacing-xl);
        }
      }

      & .center-image,
      & .vimeo-wrapper,
      & .content-wrapper,
      & .cta {
        grid-column: 1 / -1;
      }

      /* Image / Video */
      & .center-image {
        width: 100%;
        margin: 0 auto;

        &.above-copy {
          margin-bottom: var(--spacing-xs);
        }

        &.below-copy {
          margin-top: var(--spacing-xs);
        }

        &.img-width-75 {
          width: 75%;
        }

        &.img-width-50 {
          width: 50%;
        }

        &.img-width-standard {
          max-width: 400px;
        }
      }

      & .vimeo-wrapper {
        padding: 56.25% 0 0 0;
        position: relative;
        width: 100%;
        margin: 0 auto var(--spacing-xs);

        & .vimeo {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        &.squared {
          padding: 100% 0 0 0;

          @media (--tablet) {
            padding: 52.25% 0 0 0;
          }
        }
      }

      /* Content Wrapper */
      & .content-wrapper {
        text-align: center;

        & h3 {
          display: inline-block;
          position: relative;

          &.underline {
            margin-bottom: var(--spacing-m);

            &::before {
              content: '';
              position: absolute;
              width: 124px;
              background-image: url(https://images.prismic.io/tails/f9baef30-2e7c-4731-9339-8c684ab2d681_underline%402x.png?auto=compress,format);
              height: 10px;
              background-size: contain;
              bottom: -16px;
              left: 50%;
              transform: translateX(-50%);
              -webkit-transform: translateX(-50%);
              -moz-transform: translateX(-50%);
              -o-transform: translateX(-50%);
            }
          }
        }

        & h3 + p {
          margin-top: var(--spacing-xs);
        }

        & p {
          margin: 0;
          color: var(--primary-black);
        }
      }

      /* Call to action button */
      & .cta {
        margin-top: var(--spacing-xs);

        & .tails-cta.primary {
          background-color: var(--primary-blueberry);
        }

        & .tails-cta {
          margin: 0;
          width: 100%;

          @media (--phone) {
            width: auto;
          }
        }
      }

      /* Background Colours */
      &.primary-white {
        background-color: var(--primary-white);
      }

      &.pastel-lighter-frost {
        background-color: var(--pastel-lighter-frost);
      }

      &.pastel-lighter-silver {
        background-color: var(--pastel-lighter-silver);
      }

      &.pastel-lighter-sandstone {
        background-color: var(--pastel-lighter-sandstone);
      }

      &.pastel-lighter-lavender {
        background-color: var(--pastel-darker-lavender);
      }

      &.pastel-lighter-ocean-mist {
        background-color: var(--pastel-darker-ocean-mist);
      }

      &.pastel-lighter-sage {
        background-color: var(--pastel-lighter-sage);
      }

      &.pastel-lighter-beige {
        background-color: var(--pastel-lighter-beige);
      }

      &.pastel-lighter-rose {
        background-color: var(--pastel-lighter-rose);
      }

      &.coral {
        background-color: var(--secondary-01);

        & .content-wrapper p,
        & .content-wrapper h3 {
          color: var(--primary-white);
        }
      }
    }
  }
}

.section.feature-image-text {
  & .macro-feature-image-text {
    & .feature {
      & .tails-grid {
        max-width: 1024px;
        margin: 0 auto;
        padding: var(--spacing-l) 12px;

        @media (--phone) {
          padding: var(--spacing-l) var(--spacing-m);
        }

        &.left {
          & .image-small,
          & .image-medium,
          & .image-large {
            grid-column: 1/-1;
            grid-row: 2/3;
            justify-content: center;
          }

          & .content-wrapper {
            grid-column: 1/-1;
            grid-row: 1/2;
          }
        }

        &.right {
          & .image-small,
          & .image-medium,
          & .image-large {
            grid-column: 1/-1;
            grid-row: 2/3;
            justify-content: center;
          }

          & .content-wrapper {
            grid-column: 1/-1;
            grid-row: 1/2;
          }
        }

        @media (--tablet) {
          padding: var(--spacing-l) var(--spacing-l);

          &.left {
            & .image-small,
            & .image-medium,
            & .image-large {
              grid-column: 1/4;
              grid-row: 1/-1;
              justify-content: flex-start;
            }

            & .content-wrapper {
              grid-column: 4/-1;
              grid-row: 1/-1;
            }
          }

          &.right {
            & .image-small,
            & .image-medium,
            & .image-large {
              grid-column: 4/-1;
              grid-row: 1/-1;
              justify-content: flex-end;
            }

            & .content-wrapper {
              grid-column: 1/4;
              grid-row: 1/-1;
            }
          }
        }

        @media (--desktop) {
          padding: var(--spacing-l) var(--spacing-xl);

          &.left {
            & .image-small,
            & .image-medium,
            & .image-large {
              grid-column: 1/7;
              grid-row: 1/-1;
            }

            & .content-wrapper {
              grid-column: 7/-1;
              grid-row: 1/-1;
            }
          }

          &.right {
            & .image-small,
            & .image-medium,
            & .image-large {
              grid-column: 7/-1;
              grid-row: 1/-1;
            }

            & .content-wrapper {
              grid-column: 1/7;
              grid-row: 1/-1;
            }
          }
        }
      }

      /* Image */
      & span {
        & img {
          width: 100%;
          max-width: 400px;
          margin: 0;
        }

        &.image-small,
        &.image-medium,
        &.image-large {
          display: flex;
          align-items: center;
        }

        &.image-small img {
          max-width: 200px;
        }

        &.image-medium img {
          max-width: 300px;
        }

        &.image-large img {
          max-width: 500px;
        }
      }

      /* Content Wrapper */
      & .content-wrapper {
        text-align: left;
        margin: 0;

        & .content-text > *:last-child {
          padding-bottom: 0;
        }

        & h3 {
          text-align: left;
          margin-bottom: var(--spacing-s);
          display: inline-block;
          position: relative;

          &.underline {
            margin-bottom: var(--spacing-xl);

            &::before {
              content: '';
              position: absolute;
              width: 124px;
              background-image: url(https://images.prismic.io/tails/f9baef30-2e7c-4731-9339-8c684ab2d681_underline%402x.png?auto=compress,format);
              height: 10px;
              background-size: contain;
              bottom: -16px;
              left: 50%;
              transform: translateX(-50%);
              -webkit-transform: translateX(-50%);
              -moz-transform: translateX(-50%);
              -o-transform: translateX(-50%);
            }
          }
        }

        & p {
          text-align: left;
          margin-bottom: 0;
          margin-top: 0;
          padding-bottom: var(--spacing-s);
        }

        & ul,
        & ol {
          margin-top: 0;
          margin-bottom: 0;
          padding-top: 0;

          & li {
            text-align: left;
            padding-bottom: var(--spacing-xs);

            &:last-child {
              padding-bottom: 0;
            }
          }

          & + ul,
          & + ol,
          & + p {
            padding-top: var(--spacing-s);
          }
        }

        &.list-color-secondary-06 {
          & li {
            &::before {
              color: var(--secondary-06);
            }
          }
        }

        &.list-color-secondary-07 {
          & li {
            &::before {
              color: var(--secondary-07);
            }
          }
        }

        &.list-color-secondary-08 {
          & li {
            &::before {
              color: var(--secondary-08);
            }
          }
        }

        & .cta {
          text-align: center;
          margin: var(--spacing-m) 0;

          @media (--tablet) {
            text-align: left;
            margin: var(--spacing-m) 0 0;
          }

          & .btn {
            margin: 0;
            width: 100%;

            @media (--phone) {
              width: auto;
            }
          }
        }

        @media (--tablet) {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      /* Background Colours */
      &.primary-white {
        background-color: var(--primary-white);
      }

      &.pastel-light-frost {
        background-color: var(--pastel-lighter-frost);
      }

      &.pastel-light-silver {
        background-color: var(--pastel-lighter-silver);
      }

      &.pastel-light-sandstone {
        background-color: var(--pastel-lighter-sandstone);
      }

      &.pastel-lighter-lavender {
        background-color: var(--pastel-darker-lavender);
      }

      &.pastel-lighter-ocean-mist {
        background-color: var(--pastel-darker-ocean-mist);
      }

      &.pastel-lighter-sage {
        background-color: var(--pastel-lighter-sage);
      }

      &.pastel-lighter-beige {
        background-color: var(--pastel-lighter-beige);
      }

      &.pastel-lighter-rose {
        background-color: var(--pastel-lighter-rose);
      }

      &.christmas-beige {
        background-color: var(--primary-blueberry-10);
      }
    }
  }
}

.section.feature-alternating {
  & .macro-features-alternating {
    padding: 0;

    /* Top Title */
    & .title {
      margin: var(--spacing-m) 0 0;

      &. h2 {
        color: var(--primary-blueberry);
      }

      @media (--desktop) {
        margin: var(--spacing-l) 0 0;
      }
    }

    /* Main Feature Item */
    & .feature {
      & .tails-grid {
        max-width: 1024px;
        margin: 0 auto;
        grid-template-rows: repeat(2, auto);
        -ms-grid-rows: repeat(2, auto);
        padding: var(--spacing-l) 12px;

        @media (--phone) {
          padding: var(--spacing-l) var(--spacing-m);
        }

        @media (--tablet) {
          padding: var(--spacing-l) var(--spacing-l);
        }

        @media (--desktop) {
          padding: var(--spacing-l) var(--spacing-xl);
        }
      }

      /* Image */
      & .image {
        margin: 0;
        grid-row: 2 / -1;
        grid-column: 1 / -1;
        margin-top: var(--spacing-s);

        & img {
          max-width: 400px;
          width: 100%;

          @media (--tablet) {
            max-width: 100%;
            width: unset;
          }
        }
      }

      /* Content Wrapper */
      & .content-wrapper {
        text-align: left;
        grid-row: 1 / 2;
        grid-column: 1 / -1;

        @media (--phone) {
          width: 70%;
          margin: 0 auto;
        }

        & h3 {
          font-size: var(--font-size-l);
          margin-bottom: var(--spacing-xs);

          &.handwritten {
            font-size: var(--font-size-xl);
          }

          &.underline {
            position: relative;
            display: inline-block;
            margin-bottom: var(--spacing-m);

            &::before {
              content: '';
              position: absolute;
              width: 124px;
              background-image: url(https://images.prismic.io/tails/f9baef30-2e7c-4731-9339-8c684ab2d681_underline%402x.png?auto=compress,format);
              height: 10px;
              background-size: contain;
              bottom: -16px;
              left: 50%;
              transform: translateX(-50%);
              -webkit-transform: translateX(-50%);
              -moz-transform: translateX(-50%);
              -o-transform: translateX(-50%);
            }
          }
        }

        & h4 {
          font-size: var(--font-size-l);
          margin-bottom: var(--spacing-s);
        }

        & ul,
        & ol {
          margin-top: 0;
          margin-bottom: 0;
          padding-top: 0;

          & li {
            padding-bottom: var(--spacing-xs);

            &:last-child {
              padding-bottom: 0;
            }
          }
        }

        & p {
          margin-bottom: 0;
          margin-top: 0;
          padding-bottom: var(--spacing-s);
        }

        & .cta {
          text-align: center;
          margin: var(--spacing-m) 0 0;

          @media (--tablet) {
            text-align: left;
          }
        }

        &.list-color-secondary-06 {
          & li {
            &::before {
              color: var(--secondary-06);
            }
          }
        }

        &.list-color-secondary-07 {
          & li {
            &::before {
              color: var(--secondary-07);
            }
          }
        }

        &.list-color-secondary-08 {
          & li {
            &::before {
              color: var(--secondary-08);
            }
          }
        }

        & .content-text > *:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }

      @media (--tablet) {
        grid-template-rows: repeat(11, auto);
        -ms-grid-rows: repeat(11, auto);

        & .content-wrapper {
          grid-row: 1 / -1;
          grid-column: 4/7;
          display: flex;
          height: 100%;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          margin: unset;

          & h3 {
            text-align: left;
          }
        }

        & .image {
          grid-row: 1 / -1;
          grid-column: 1 / 4;
          display: flex;
          height: 100%;
          justify-content: center;
          flex-direction: column;
          margin-top: 0;
        }

        &:nth-child(even) {
          & .content-wrapper {
            grid-column: 1 / 4;
          }

          & .image {
            grid-column: 4 / 7;
            align-items: flex-end;
          }
        }
      }

      @media (--desktop) {
        & .content-wrapper {
          grid-column: 7/-1;
        }

        & .image {
          grid-column: 1 / 7;
        }

        &:nth-child(even) {
          & .content-wrapper {
            grid-column: 2 / 7;
          }

          & .image {
            grid-column: 7 / -1;
            align-items: flex-end;
          }
        }
      }

      &.primary-white {
        background-color: var(--primary-white);
      }

      &.pastel-lighter-frost {
        background-color: var(--pastel-lighter-frost);
      }

      &.pastel-lighter-silver {
        background-color: var(--pastel-lighter-silver);
      }

      &.pastel-lighter-sandstone {
        background-color: var(--pastel-lighter-sandstone);
      }

      &.pastel-lighter-lavender {
        background-color: var(--pastel-darker-lavender);
      }

      &.pastel-lighter-ocean-mist {
        background-color: var(--pastel-darker-ocean-mist);
      }

      &.pastel-lighter-sage {
        background-color: var(--pastel-lighter-sage);
      }

      &.pastel-lighter-beige {
        background-color: var(--pastel-lighter-beige);
      }

      &.pastel-lighter-rose {
        background-color: var(--pastel-lighter-rose);
      }

      &.christmas-beige {
        background-color: var(--primary-blueberry-10);
      }
    }
  }
}

.section.two-column {
  & .macro-two-column {
    padding: var(--spacing-l) 0;

    & p {
      margin: 0 0 var(--spacing-s);
    }

    @media (--desktop) {
      & p {
        margin: 0;
      }
    }

    & .tails-grid,
    & .title-section {
      padding: 0 12px;
      max-width: 1024px;
      margin: 0 auto;

      @media (--phone) {
        padding: 0 var(--spacing-m);
      }

      @media (--tablet) {
        padding: 0 var(--spacing-l);
      }

      @media (--desktop) {
        padding: 0 var(--spacing-xl);
      }
    }

    /* Title */
    & .title-section {
      text-align: left;
      margin-bottom: var(--spacing-s);

      @media (--desktop) {
        text-align: center;
        max-width: 50%;
        margin: 0 auto var(--spacing-s);
      }

      & h3 + p {
        margin-top: var(--spacing-xxs);
      }

      & h3 {
        display: inline-block;
        position: relative;

        &.underline {
          margin-bottom: var(--spacing-m);
          margin-left: 40px;

          &::before {
            content: '';
            position: absolute;
            width: 124px;
            background-image: url(https://images.prismic.io/tails/f9baef30-2e7c-4731-9339-8c684ab2d681_underline%402x.png?auto=compress,format);
            height: 10px;
            background-size: contain;
            bottom: -16px;
            left: 50%;
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -o-transform: translateX(-50%);
          }

          @media (--desktop) {
            margin-left: 0;
          }
        }
      }
    }

    /* Feature Wrapper */
    & .feature-wrapper {
      & .feature {
        margin-bottom: var(--spacing-m);
        grid-column: 1 / -1;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;

        @media (--desktop) {
          grid-column: span 6;
          flex-direction: column;

          &:nth-last-child(-n+2):nth-child(odd) {
            margin-bottom: 0;
          }

          &:last-child:nth-child(even) {
            margin-bottom: 0;
          }

          &:last-child:nth-child(odd) {
            grid-column: span 12;
            width: 50%;
            margin: 0 auto;
          }
        }

        /* Image */
        & .feature-image {
          @media (--desktop) {
            margin-bottom: var(--spacing-m);
          }

          & img {
            width: 100%;
            max-width: 100%;
          }

          &.img-size-small {
            width: 44%;
          }

          &.img-size-large {
            width: 63%;
          }

          &.img-size-icon_small {
            width: 48px;

            @media (--desktop) {
              margin-bottom: var(--spacing-xs);
            }
          }

          &.img-size-icon_large {
            width: 96px;

            @media (--desktop) {
              margin-bottom: var(--spacing-xs);
            }
          }

          & + .feature-content .description p {
            margin: 0 0 var(--spacing-s);
          }
        }

        /* Feature Content */
        & .feature-content {
          width: 100%;

          & h4 {
            margin-bottom: var(--spacing-s);
            font-weight: bold;
            font-size: var(--font-size-body);

            &.underline {
              position: relative;
            }

            &.underline::before {
              content: '';
              position: absolute;
              width: 124px;
              background-image: url(https://images.prismic.io/tails/f9baef30-2e7c-4731-9339-8c684ab2d681_underline%402x.png?auto=compress,format);
              height: 10px;
              background-size: contain;
              bottom: -16px;
              left: 50%;
              transform: translateX(-50%);
              -webkit-transform: translateX(-50%);
              -moz-transform: translateX(-50%);
              -o-transform: translateX(-50%);
            }
          }

          & h4:not(.underline) + .description {
            margin-top: -16px;
          }

          & .description p {
            margin-bottom: 0;
          }
        }

        /* Position Styles */
        &.img-position-left {
          flex-direction: column-reverse;
          align-items: center;
          column-gap: 3rem;

          @media (--desktop) {
            flex-direction: row;
            align-items: unset;
          }

          & .feature-image {
            margin-bottom: 0;
          }

          @media (--desktop) {
            & .feature-image.img-size-small {
              width: 32%;
            }

            & .feature-image.img-size-small + .feature-content {
              width: 68%;
            }

            & .feature-image.img-size-large {
              width: 50%;
            }

            & .feature-image.img-size-large + .feature-content {
              width: 50%;
            }
          }
        }

        &.copy-align-left {
          & .feature-content {
            text-align: left;
          }
        }

        &.copy-align-center {
          & .feature-content {
            text-align: center;
          }
        }
      }
    }

    /* Background Colours */
    &.primary-white {
      background-color: var(--primary-white);
    }

    &.pastel-lighter-frost {
      background-color: var(--pastel-lighter-frost);
    }

    &.pastel-lighter-silver {
      background-color: var(--pastel-lighter-silver);
    }

    &.pastel-lighter-sandstone {
      background-color: var(--pastel-lighter-sandstone);
    }

    &.pastel-lighter-lavender {
      background-color: var(--pastel-darker-lavender);
    }

    &.pastel-lighter-ocean-mist {
      background-color: var(--pastel-darker-ocean-mist);
    }

    &.pastel-lighter-sage {
      background-color: var(--pastel-lighter-sage);
    }

    &.pastel-lighter-beige {
      background-color: var(--pastel-lighter-beige);
    }

    &.pastel-lighter-rose {
      background-color: var(--pastel-lighter-rose);
    }
  }
}

.section.three-column {
  & .macro-three-column {
    & .tails-grid {
      max-width: 1024px;
      margin: 0 auto;
      padding: 0 12px;

      @media (--phone) {
        padding: 0 var(--spacing-m);
      }

      @media (--tablet) {
        padding: 0 var(--spacing-l);
      }

      @media (--desktop) {
        padding: 0 var(--spacing-xl);
      }
    }

    &:not(.title) .tails-grid {
      padding: var(--spacing-m) 12px;

      @media (--phone) {
        padding: var(--spacing-m) var(--spacing-m);
      }

      @media (--tablet) {
        padding: var(--spacing-m) var(--spacing-l);
      }

      @media (--desktop) {
        padding: var(--spacing-s) var(--spacing-xl);
      }
    }

    &.title {
      padding-top: var(--spacing-l);
      padding-bottom: var(--spacing-l);

      & .column-wrapper > *:not(:last-child) {
        margin-bottom: 32px;

        @media (--desktop) {
          margin-bottom: 0;
        }
      }
    }

    /* Visiblity Classes */
    &.mobile-only {
      @media (--desktop) {
        display: none;
      }
    }

    &.desktop-only {
      display: none;

      @media (--desktop) {
        display: flex;
      }
    }

    /* Section Title */
    & .title-container {
      width: 100%;
      grid-column: 1 / -1;

      &.left {
        text-align: left;
      }

      &.centre {
        text-align: center;
      }
    }

    & h3 {
      position: relative;
      display: inline-block;
      margin: 0 0 12px;
      font-size: 3rem;

      &.underline {
        margin: 0 0 var(--spacing-xl);

        &::before {
          content: '';
          position: absolute;
          width: 124px;
          background-image: url(https://images.prismic.io/tails/f9baef30-2e7c-4731-9339-8c684ab2d681_underline%402x.png?auto=compress,format);
          height: 10px;
          background-size: contain;
          bottom: -16px;
          left: 50%;
          transform: translateX(-50%);
          -webkit-transform: translateX(-50%);
          -moz-transform: translateX(-50%);
          -o-transform: translateX(-50%);
        }
      }

      @media (--tablet) {
        margin: 0 0 var(--spacing-m);
      }
    }

    & .title-underline {
      width: 100%;
      grid-column: 1 / -1;
      margin-top: -24px;
      margin-bottom: 32px;

      & img {
        width: 124px;
        display: block;
        margin: 0 auto;
      }
    }

    & .column-wrapper {
      display: grid;
      grid-column: 1 / -1;

      @media (--desktop) {
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: var(--spacing-m);
      }

      /* Mobile Alignment */
      &.mobile-align-left {
        & .row {
          justify-content: flex-end;
          text-align: left;

          @media (--desktop) {
            justify-content: center;
          }
        }

        & .column {
          justify-content: flex-end;
          text-align: left;

          @media (--desktop) {
            justify-content: flex-start;
          }
        }
      }

      /* Item Title */
      & h4 {
        margin: 0 0 var(--spacing-xxs);

        &.underline {
          position: relative;
          display: inline-block;

          &::before {
            content: '';
            position: absolute;
            width: 124px;
            background-image: url(https://images.prismic.io/tails/f9baef30-2e7c-4731-9339-8c684ab2d681_underline%402x.png?auto=compress,format);
            height: 10px;
            background-size: contain;
            bottom: -16px;
            left: 50%;
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -o-transform: translateX(-50%);
          }
        }
      }

      & .row,
      & .column {
        margin-bottom: var(--spacing-xxs);

        @media (--desktop) {
          margin-bottom: 0 !important;
        }
      }

      & .column-text-container {
        text-align: left;

        & .description-footer > *:last-child,
        & .description > *:last-child {
          margin-bottom: 0;
        }

        & p {
          margin: 0 0 var(--spacing-xxs);

          @media (--desktop) {
            margin: 0 0 var(--spacing-xs);
          }
        }

        & h4 + .description,
        & .description + .description-footer,
        & h4 + .description-footer {
          margin-top: var(--spacing-xxs);

          @media (--desktop) {
            margin-top: var(--spacing-xs);
          }
        }

        & h4.underline + .description {
          margin-top: var(--spacing-s);

          @media (--desktop) {
            margin-top: var(--spacing-m);
          }
        }

        & h4 {
          margin: 0;
          color: var(--primary-blueberry);
        }
      }

      /* Row Item */
      & .row {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;

        @media (--desktop) {
          text-align: left;
        }

        & .image {
          margin: 0 var(--spacing-s) 0 0;

          &.small {
            & img {
              height: 90px;
            }
          }

          &.large img {
            height: 90px;

            @media (--tablet) {
              height: 180px;
            }
          }

          &.icon,
          &.icon_small {
            & img {
              height: 48px;
            }
          }

          &.icon_large {
            & img {
              height: 96px;
            }
          }
        }
      }

      /* Column Item */
      & .column {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;

        @media (--desktop) {
          text-align: left;
          justify-content: flex-start;
          flex-direction: column;
        }

        & .image {
          margin-right: var(--spacing-s);

          @media (--desktop) {
            margin-right: 0;
            margin-top: var(--spacing-s);
          }

          &.small {
            & img {
              height: 90px;
            }
          }

          &.large img {
            height: 136px;

            @media (--desktop) {
              height: 288px;
            }
          }

          &.icon,
          &.icon_small {
            & img {
              height: 48px;
            }
          }

          &.icon_large {
            & img {
              height: 96px;
            }
          }
        }
      }

      /* Spacing Fixers */
      & > *:last-child {
        margin-bottom: 0;
      }

      @media (--desktop) {
        & > *:not(.column):nth-child(3n + 1) {
          justify-content: flex-end;
        }

        & > *:not(.column):nth-child(3n + 3) {
          justify-content: flex-start;
        }

        & > *:not(.row):nth-child(3n + 1) {
          align-items: flex-start;
        }

        & > *:not(.row):nth-child(3n + 3) {
          align-items: flex-start;
        }

        & > *:nth-child(3n + 4),
        & > *:nth-child(3n + 5),
        & > *:nth-child(3n + 6) {
          margin-top: var(--spacing-m);
        }
      }
    }

    /* Section CTA */
    & .cta {
      grid-column: 1 / -1;
    }

    & .btn {
      margin: 0 -8px;
      margin-top: var(--spacing-m);

      & .btn {
        margin: 0;
      }
    }

    /* Background colours */
    &.primary-white {
      background-color: var(--primary-white);
    }

    &.pastel-light-frost {
      background-color: var(--pastel-lighter-frost);
    }

    &.pastel-light-silver {
      background-color: var(--pastel-lighter-silver);
    }

    &.pastel-light-sandstone {
      background-color: var(--pastel-lighter-sandstone);
    }

    &.pastel-lighter-lavender {
      background-color: var(--pastel-darker-lavender);
    }

    &.pastel-lighter-ocean-mist {
      background-color: var(--pastel-darker-ocean-mist);
    }

    &.pastel-lighter-sage {
      background-color: var(--pastel-lighter-sage);
    }

    &.pastel-lighter-beige {
      background-color: var(--pastel-lighter-beige);
    }

    &.pastel-lighter-rose {
      background-color: var(--pastel-lighter-rose);
    }

    &.christmas-beige {
      background-color: var(--primary-blueberry-10);
    }
  }
}

.section.four-column {
  & .macro-four-column {
    & .tails-grid {
      max-width: 1024px;
      margin: 0 auto;
      padding: var(--spacing-l) 12px;
      grid-template-rows: repeat(3, minmax(var(--spacing-m), auto));
      grid-auto-rows: var(--spacing-m);

      @media (--mobile) {
        padding: var(--spacing-l) var(--spacing-m);
      }

      @media (--tablet) {
        padding: var(--spacing-l) var(--spacing-l);
        grid-template-rows: repeat(2, minmax(var(--spacing-m), auto));

        --cols: 8;
      }

      @media (--desktop) {
        padding: var(--spacing-l) var(--spacing-xl);

        --cols: 12;
      }
    }

    & .title-container {
      grid-column: 1 / -1;

      &.align-centre {
        text-align: center;
      }
    }

    & h3 {
      display: inline-block;
      position: relative;
      margin-bottom: var(--spacing-s);

      &.underline {
        margin: 0 0 var(--spacing-xl);

        &::before {
          content: '';
          position: absolute;
          width: 124px;
          background-image: url(https://images.prismic.io/tails/f9baef30-2e7c-4731-9339-8c684ab2d681_underline%402x.png?auto=compress,format);
          height: 10px;
          background-size: contain;
          bottom: -16px;
          left: 50%;
          transform: translateX(-50%);
          -webkit-transform: translateX(-50%);
          -moz-transform: translateX(-50%);
          -o-transform: translateX(-50%);
        }
      }
    }

    & .content-wrapper {
      grid-column: span 2;

      @media (--desktop) {
        grid-column: span 3;
      }

      & img {
        width: 100%;
      }

      & p {
        margin: var(--spacing-xs) 0;
        font-size: 16px;

        @media (--desktop) {
          margin: var(--spacing-xxs) 0;
        }

        &.underline {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            width: 124px;
            background-image: url(https://images.prismic.io/tails/f9baef30-2e7c-4731-9339-8c684ab2d681_underline%402x.png?auto=compress,format);
            height: 10px;
            background-size: contain;
            bottom: -16px;
            left: 50%;
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -o-transform: translateX(-50%);
          }
        }
      }
    }

    &.primary-white {
      background-color: var(--primary-white);
    }

    &.pastel-light-frost {
      background-color: var(--pastel-lighter-frost);
    }

    &.pastel-light-silver {
      background-color: var(--pastel-lighter-silver);
    }

    &.pastel-light-sandstone {
      background-color: var(--pastel-lighter-sandstone);
    }

    &.pastel-lighter-lavender {
      background-color: var(--pastel-darker-lavender);
    }

    &.pastel-lighter-ocean-mist {
      background-color: var(--pastel-darker-ocean-mist);
    }

    &.pastel-lighter-sage {
      background-color: var(--pastel-lighter-sage);
    }

    &.pastel-lighter-beige {
      background-color: var(--pastel-lighter-beige);
    }

    &.pastel-lighter-rose {
      background-color: var(--pastel-lighter-rose);
    }

    &.christmas-beige {
      background-color: var(--primary-blueberry-10);
    }
  }
}

.section.maropost {
  & .macro-maropost {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    @media (--desktop) {
      flex-direction: row;
    }

    &.paper {
      background-color: var(--primary-blueberry-10);
    }

    &.image_position_right {
      @media (--desktop) {
        flex-direction: row-reverse;
      }
    }

    & .image-box {
      width: 100%;
      height: 200px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      @media (--desktop) {
        background-size: 0;
        width: auto;
        height: auto;
      }

      & .feature-image {
        display: none;

        @media (--desktop) {
          display: block;
          width: 400px;
        }
      }
    }

    & .content-box {
      padding: 30px 20px;
      text-align: center;
      width: 100%;

      @media (--desktop) {
        text-align: left;
        padding: 50px 90px;
      }

      & h3.title,
      & p:last-of-type {
        margin-bottom: 20px;
      }

      & form {
        & label {
          margin-bottom: 10px;
          text-align: left;
          display: block;
        }

        & #contact_fields_email,
        & #contact_fields_first_name {
          width: 100%;
          margin-bottom: 30px;
        }
      }
    }
  }
}

.section.accordion {
  & .macro-accordion {
    padding: var(--spacing-l) 12px;
    max-width: 1024px;
    margin: 0 auto;
    text-align: left;

    @media (--phone) {
      padding: var(--spacing-l) var(--spacing-m);
    }

    @media (--tablet) {
      padding: var(--spacing-l) var(--spacing-l);
    }

    @media (--desktop) {
      padding: var(--spacing-l) var(--spacing-xl);
    }

    & .accordion-header {
      margin-bottom: var(--spacing-s);

      & .intro {
        margin: 0;
      }

      & h3 + .intro {
        margin-top: var(--spacing-xxs);
      }

      & h3 {
        &.underline {
          position: relative;
          display: inline-block;
          margin-bottom: 16px;
          margin-left: 40px;

          &::before {
            content: '';
            position: absolute;
            width: 124px;
            background-image: url(https://images.prismic.io/tails/f9baef30-2e7c-4731-9339-8c684ab2d681_underline%402x.png?auto=compress,format);
            height: 10px;
            background-size: contain;
            bottom: -16px;
            left: 50%;
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -o-transform: translateX(-50%);
          }
        }
      }
    }

    & .accordion-wrapper {
      margin: 0 auto;
      width: 100%;
      max-width: 608px;

      & .feature {
        border-top: 1px solid var(--pastel-darker-silver);

        &:last-child {
          border-bottom: 1px solid var(--pastel-darker-silver);
        }

        & .content-wrapper {
          padding: 4px var(--spacing-xxs);

          & label {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            min-height: 48px;
            position: relative;

            &::after {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              height: 48px;
              width: 48px;
              background-image: url('../svg/chevron-down-thin.svg');
              background-size: 28px;
              background-position: center;
              background-repeat: no-repeat;
              transition: transform 0.5s ease;
            }

            & .accordion-tab-title {
              color: var(--primary-text-link);
              font-size: var(--font-size-body);
              font-weight: 500;
              transition: all 0.2s ease;
              text-decoration: none;
              text-align: left;
              margin: 12px 0;
              padding-right: 56px;
            }

            & .accordion-tab-title:focus-visible {
              outline: 0;
              box-shadow: 0 0 10px 2px var(--secondary-03);
            }

            &:hover .accordion-tab-title {
              color: var(--primary-colour);
              text-decoration: underline;
            }

            &:active .accordion-tab-title {
              color: var(--primary-cta);
              text-decoration: underline;
            }
          }

          & .accordion-content {
            display: block;
            overflow: hidden;
            transition: max-height 0.5s ease, margin 0.5s ease;
            max-height: 0;
            margin: 0;

            &.closed {
              max-height: 0;
            }
          }

          & input[type=checkbox]:checked {
            & ~ .accordion-content {
              margin-top: 4px;
              margin-bottom: var(--spacing-xxs);
            }

            & ~ label::after {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    /* Background Colours */
    &.primary-white {
      background-color: var(--primary-white);
    }

    &.pastel-lighter-frost {
      background-color: var(--pastel-lighter-frost);
    }

    &.pastel-lighter-silver {
      background-color: var(--pastel-lighter-silver);
    }

    &.pastel-lighter-sandstone {
      background-color: var(--pastel-lighter-sandstone);
    }

    &.pastel-lighter-lavender {
      background-color: var(--pastel-darker-lavender);
    }

    &.pastel-lighter-ocean-mist {
      background-color: var(--pastel-darker-ocean-mist);
    }

    &.pastel-lighter-sage {
      background-color: var(--pastel-lighter-sage);
    }

    &.pastel-lighter-beige {
      background-color: var(--pastel-lighter-beige);
    }

    &.pastel-lighter-rose {
      background-color: var(--pastel-lighter-rose);
    }

    &.christmas-beige {
      background-color: var(--primary-blueberry-10);
    }
  }
}

.section.multi-accordion {
  & .macro-multi-accordion {
    padding: var(--spacing-l) 12px;
    max-width: 1024px;
    margin: 0 auto;

    @media (--phone) {
      padding: var(--spacing-l) var(--spacing-m);
    }

    @media (--tablet) {
      padding: var(--spacing-l) var(--spacing-l);
    }

    @media (--desktop) {
      padding: var(--spacing-l) var(--spacing-xl);
    }

    &.collapsed {
      padding-top: 0;
    }

    & .multi-accordion-header {
      margin-bottom: var(--spacing-s);
      text-align: left;

      & .intro {
        margin: 0;
      }

      & h3 + .intro {
        margin-top: var(--spacing-xxs);
      }
    }

    & .multi-accordion-wrapper {
      margin: 0 auto;
      width: 100%;
      max-width: 608px;

      & .feature {
        border-top: 1px solid var(--pastel-darker-silver);
        border-bottom: 1px solid var(--pastel-darker-silver);
      }

      & .content-wrapper {
        padding: 4px var(--spacing-xxs);

        & label {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          min-height: 48px;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            height: 48px;
            width: 48px;
            background-image: url('../svg/chevron-down-thin.svg');
            background-size: 28px;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.5s ease;
          }

          & .accordion-tab-title {
            color: var(--primary-text-link);
            font-size: var(--font-size-body);
            font-weight: 500;
            transition: all 0.2s ease;
            text-decoration: none;
            padding-right: 56px;
            text-align: left;
            margin: 12px 0;
          }

          & .accordion-tab-title:focus-visible {
            outline: 0;
            box-shadow: 0 0 10px 2px var(--secondary-03);
          }

          &:hover .accordion-tab-title {
            color: var(--primary-colour);
            text-decoration: underline;
          }

          &:active .accordion-tab-title {
            color: var(--primary-cta);
            text-decoration: underline;
          }
        }

        & .accordion-content {
          display: block;
          overflow: hidden;
          transition: max-height 0.5s ease, margin 0.5s ease;
          margin: 0;

          &.closed {
            max-height: 0;
          }

          & .tails-grid:not(:last-child) {
            margin-bottom: var(--spacing-s);
          }

          & .multi-accordion-description {
            grid-column: 3 / 5;
            width: 100%;

            @media (--tablet) {
              grid-column: 4 / 7;
            }

            @media (--desktop) {
              grid-column: 7 / 13;
            }
          }

          & img.image {
            grid-column: 1 / 3;
            object-fit: cover;
            max-height: 100%;
            max-width: 100%;
            margin: auto;

            @media (--tablet) {
              grid-column: 1 / 4;
            }

            @media (--desktop) {
              grid-column: 1 / 7;
            }
          }
        }

        & input[type=checkbox]:checked {
          & ~ .accordion-content {
            margin-top: 4px;
            margin-bottom: var(--spacing-xxs);
          }

          & ~ label::after {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  /* Background Colours */
  & .primary-white {
    background-color: var(--primary-white);
  }

  & .pastel-lighter-frost {
    background-color: var(--pastel-lighter-frost);
  }

  & .pastel-lighter-silver {
    background-color: var(--primary-blueberry-10);
  }

  & .pastel-lighter-sandstone {
    background-color: var(--primary-blueberry-10);
  }

  & .pastel-lighter-lavender {
    background-color: var(--primary-blueberry-10);
  }

  & .pastel-lighter-ocean-mist {
    background-color: var(--primary-blueberry-10);
  }

  & .pastel-lighter-sage {
    background-color: var(--primary-blueberry-10);
  }

  & .pastel-lighter-beige {
    background-color: var(--primary-blueberry-10);
  }

  & .pastel-lighter-rose {
    background-color: var(--primary-blueberry-10);
  }

  & .christmas-beige {
    background-color: var(--primary-blueberry-10);
  }
}

.section.carousel {
  padding: 0;
  text-align: center;
  width: 100%;

  & .macro-carousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 20px 40px 20px;

    @media (--desktop) {
      padding: 30px;
    }

    &.mobile-only {
      @media (--desktop) {
        display: none;
      }
    }

    &.desktop-only {
      display: none;

      @media (--desktop) {
        display: flex;
      }
    }

    &.white {
      background-color: var(--primary-white);
    }

    &.paper {
      background-color: var(--primary-blueberry-10);
    }

    &.light-blue {
      background-color: var(--primary-blueberry-10);
    }

    & .title {
      max-width: 550px;
      margin: 10px 0;
    }
  }

  & .carousel {
    background-color: transparent;
    width: 100%;

    @media (--desktop) {
      width: 80%;
    }
  }

  & .carousel-cell {
    min-height: min-content;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: transparent;

    @media (--desktop) {
      min-height: min-content;
      flex-direction: row;
      padding: 20px 50px;
    }
  }

  & .image-wrapper {
    margin: 0;
    width: 100%;
    overflow: hidden;

    @media (--desktop) {
      width: auto;
      overflow: visible;
    }

    & img.image {
      width: auto;
      height: 250px;

      @media (--desktop) {
        height: 350px;
      }
    }
  }

  & .icon-wrapper {
    margin: 0 auto;
    width: 85px;

    & img.image {
      width: 100%;
    }
  }

  & .description {
    margin: 0;

    &.left {
      text-align: left;
    }

    &.center {
      text-align: center;
    }

    @media (--desktop) {
      margin: 20px;
    }
  }
}

.section.multicarousel {
  padding: 0;
  text-align: center;
  width: 100%;
  max-width: 100%;
  display: inline-block;

  @media (--tablet) {
    width: 33%;
    max-width: 426px;
  }

  & .macro-multicarousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px 20px 20px;

    @media (--tablet) {
      padding: 0 30px 30px 30px;
    }

    &.paper {
      background-color: var(--primary-blueberry-10);
    }
  }

  & .carousel {
    width: 100%;
    background-color: var(--primary-blueberry-10);
  }

  & .flickity-prev-next-button {
    background-color: transparent;
  }

  & .flickity-page-dots {
    bottom: 0;
  }

  & .carousel-cell {
    min-height: 400px;
    padding: 20px 40px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @media (--tablet) {
      min-height: 420px;
      padding: 20px 50px;
    }
  }

  & .image-wrapper {
    margin: 0;
    width: 100%;
    overflow: hidden;

    @media (--tablet) {
      width: auto;
      overflow: visible;
    }

    & img.image {
      width: auto;
      height: 200px;
    }
  }

  & .description {
    margin: 10px;
    text-align: center;
  }
}

.section.trustpilot {
  & h4 {
    font-size: var(--font-size-s);

    @media (--tablet) {
      font-size: var(--font-size-body));
    }
  }

  & p {
    font-size: var(--font-size-xs);

    @media (--tablet) {
      font-size: var(--font-size-xs);
    }
  }

  & .paper {
    background-color: var(--primary-blueberry-10);

    & .carousel {
      & .flickity-prev-next-button {
        background-color: var(--primary-blueberry-10);
      }

      & .carousel-cell {
        background-color: var(--primary-blueberry-10);
      }
    }
  }

  & .white {
    background-color: var(--primary-white);

    & .carousel {
      & .flickity-prev-next-button {
        background-color: var(--primary-white);
      }

      & .carousel-cell {
        background-color: var(--primary-white);
      }
    }
  }

  & > div {
    padding: 20px 0 40px 0;

    & div.title-section {
      margin: 20px 0;
      text-align: center;
      border-bottom: solid 1px lightgray;

      @media (--tablet) {
        margin: 40px 100px;
      }

      & > a {
        text-decoration: none;

        & :hover {
          color: var(--primary-colour);
        }
      }

      & h3 {
        display: inline-block;

        & img.trustpilot {
          @media (--tablet) {
            height: 50px;
          }
        }
      }
    }

    & .carousel {
      & .carousel-cell {
        padding: 25px 60px;

        @media (--tablet) {
          width: 31%;
          padding: 25px 15px 0 15px;
        }

        & > a {
          text-decoration: none;

          .section.accordion .macro-accordion p {
            margin: 0;

            &.intro {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}

.section.logo {
  & .macro-logos {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 20px 40px 20px;

    @media (--tablet) {
      flex-direction: row;
      justify-content: space-evenly;
    }

    & h5 {
      &.underline {
        position: relative;
        margin-bottom: var(--spacing-xs);

        &::before {
          content: '';
          position: absolute;
          width: 124px;
          background-image: url(https://images.prismic.io/tails/f9baef30-2e7c-4731-9339-8c684ab2d681_underline%402x.png?auto=compress,format);
          height: 10px;
          background-size: contain;
          bottom: -16px;
          left: 50%;
          transform: translateX(-50%);
          -webkit-transform: translateX(-50%);
          -moz-transform: translateX(-50%);
          -o-transform: translateX(-50%);
        }
      }
    }

    & .logo img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 40px;
      margin: 10px 0;
    }

    &.primary-white {
      background-color: var(--primary-white);
    }

    &.pastel-lighter-frost {
      background-color: var(--pastel-lighter-frost);
    }

    &.pastel-lighter-silver {
      background-color: var(--pastel-lighter-silver);
    }

    &.pastel-lighter-sandstone {
      background-color: var(--pastel-lighter-sandstone);
    }

    &.pastel-lighter-lavender {
      background-color: var(--pastel-darker-lavender);
    }

    &.pastel-lighter-ocean-mist {
      background-color: var(--pastel-darker-ocean-mist);
    }

    &.pastel-lighter-sage {
      background-color: var(--pastel-lighter-sage);
    }

    &.pastel-lighter-beige {
      background-color: var(--pastel-lighter-beige);
    }

    &.pastel-lighter-rose {
      background-color: var(--pastel-lighter-rose);
    }
  }

  & .logo {
    margin-top: 20px;
  }
}

.section.pricing {
  & .macro-pricing {
    & .tails-grid {
      max-width: 1024px;
      margin: 0 auto;
      padding: var(--spacing-l) 12px;

      @media (--phone) {
        padding: var(--spacing-l) var(--spacing-m);
      }

      @media (--tablet) {
        padding: var(--spacing-l) var(--spacing-l);
      }

      @media (--desktop) {
        padding: var(--spacing-l) var(--spacing-xl);
      }
    }

    /* Slice Title */
    & .description {
      text-align: left;
      width: 100%;
      margin: 0;
      grid-column: 1 / -1;
      margin-bottom: var(--spacing-s);

      @media (--desktop) {
        width: 50%;
      }

      &.centre {
        text-align: center;
        margin: 0 auto var(--spacing-s);
      }
    }

    & .title-container {
      grid-column: 1 / -1;
      text-align: left;

      &.centre {
        text-align: center;

        & h3.underline {
          width: 50%;
        }
      }

      & h3 {
        display: inline-block;
        margin-bottom: var(--spacing-s);
        position: relative;

        &.underline {
          margin-bottom: var(--spacing-xl);

          &::before {
            content: '';
            position: absolute;
            width: 124px;
            background-image: url(https://images.prismic.io/tails/f9baef30-2e7c-4731-9339-8c684ab2d681_underline%402x.png?auto=compress,format);
            height: 10px;
            background-size: contain;
            bottom: -16px;
            left: 50%;
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -o-transform: translateX(-50%);
          }
        }
      }
    }

    /* Columns */
    & .columns-wrapper {
      grid-column: 1 / -1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media (--desktop) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
      }

      /* Column */
      & .column-wrapper {
        margin-bottom: var(--spacing-l);
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
        width: 100%;

        &:last-child {
          margin-bottom: 0;
        }

        @media (--desktop) {
          display: flex;
          margin-bottom: 0;
          width: 20%;
          flex-flow: column;
          justify-content: unset;

          & .image-wrapper + .image-caption {
            margin-top: var(--spacing-xs);
          }
        }

        & .image-title + .image-wrapper,
        & .image-title + .image-caption {
          margin-top: var(--spacing-xs);
        }

        & .image-title + .image-wrapper {
          margin-top: 0;

          @media (--desktop) {
            margin-top: var(--spacing-xs);
          }
        }

        & .image-title.underline + .image-wrapper {
          @media (--desktop) {
            margin-top: var(--spacing-m);
          }
        }

        & .image-title {
          font-size: var(--font-size-m);
          margin: 0;
          text-align: left;
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;

          @media (--desktop) {
            text-align: center;
            justify-content: center;
          }

          @media (--desktop) {
            &.underline {
              position: relative;
            }

            &.underline::before {
              content: '';
              position: absolute;
              width: 124px;
              background-image: url(https://images.prismic.io/tails/f9baef30-2e7c-4731-9339-8c684ab2d681_underline%402x.png?auto=compress,format);
              height: 10px;
              background-size: contain;
              bottom: -16px;
              left: 50%;
              transform: translateX(-50%);
              -webkit-transform: translateX(-50%);
              -moz-transform: translateX(-50%);
              -o-transform: translateX(-50%);
            }
          }
        }

        /* Column Caption */
        & .image-caption {
          text-align: left;
          grid-column: 2;

          @media (--desktop) {
            text-align: center;
          }

          & p {
            margin: 0;
            width: 100%;
          }
        }

        /* Column Image Wrapper */
        & .image-wrapper {
          grid-column: 1;
          grid-row: 1 / -3;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          @media (--desktop) {
            display: block;
          }

          & .image-1 {
            width: 67px;
          }

          & .image-2 {
            width: 98px;
          }

          & .image-3 {
            width: 85px;
          }

          & .image-4 {
            width: 125px;
          }

          & .image-5 {
            width: 120px;
          }
        }
      }
    }

    /* Cta */
    & .cta {
      grid-column: 1 / -1;
      margin-top: var(--spacing-m);

      & .btn {
        margin: 0;
        width: 100%;

        @media (--phone) {
          width: auto;
        }
      }
    }

    /* Background Colours */
    &.primary-white {
      background-color: var(--primary-white);
    }

    &.pastel-lighter-frost {
      background-color: var(--pastel-lighter-frost);
    }

    &.pastel-lighter-silver {
      background-color: var(--pastel-lighter-silver);
    }

    &.pastel-lighter-sandstone {
      background-color: var(--pastel-lighter-sandstone);
    }

    &.pastel-lighter-lavender {
      background-color: var(--pastel-darker-lavender);
    }

    &.pastel-lighter-ocean-mist {
      background-color: var(--pastel-darker-ocean-mist);
    }

    &.pastel-lighter-sage {
      background-color: var(--pastel-lighter-sage);
    }

    &.pastel-lighter-beige {
      background-color: var(--pastel-lighter-beige);
    }

    &.pastel-lighter-rose {
      background-color: var(--pastel-lighter-rose);
    }
  }
}

.section.review-banner {
  & .macro-review-banner {
    position: relative;

    & picture img {
      background-position: center center;
      background-size: cover;
      position: relative;
      max-width: 100%;
    }

    & .review-overlay {
      background-color: #fff;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      max-width: 100%;
      margin: 0 2rem;
      transform: translateY(-50%);
      padding: 3rem 1.5rem;

      @media (--desktop) {
        padding: 3rem 4rem;
        max-width: 50%;
        margin: 0 auto;
      }

      & .reviewer {
        font-weight: 600;
        margin-top: 1.5rem;
      }
    }
  }
}
